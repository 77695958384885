// /auth/dialog/authorize

'use strict';

angular.module('enervexSalesappApp').controller('InvitationCtrl', function($scope, Invitation, AccountInvitation, $stateParams, $cookies, $http, Flash, Auth) {
	$scope.invitation = null;
	$scope.completed = false;
	$scope.approved = false;
	$scope.rejected = false;

	Auth.getSession(false).then(function() {
		console.log("params "+JSON.stringify($stateParams))
		if ($stateParams.jobId){
			Invitation.get({
				id: $stateParams.token,
				jobId: $stateParams.jobId,
				accountId: $stateParams.accountId
			}).$promise.then(function(invitation) {
				console.log("you have "+JSON.stringify(invitation))
				$scope.invitation = invitation;
			}).catch(function(e){
				console.log(e)
			})
		} else {
			AccountInvitation.get({
				id: $stateParams.token,
				accountId: $stateParams.accountId
			}).$promise.then(function(invitation) {
				console.log("you have "+JSON.stringify(invitation))
				$scope.invitation = invitation;
			}).catch(function(e){
				console.log(e)
			})
		}
		$scope.accept = function() {
			if ($stateParams.jobId){
				Invitation.accept({
					id:$scope.invitation.token,
					jobId: $stateParams.jobId,
					accountId: $stateParams.accountId
				}, {
				}).$promise.then(function(){
					$scope.completed = true;
					
					$scope.approved = true;
					var url = "/accounts/"+$stateParams.accountId + "/jobs/"+$stateParams.jobId;
					var message = '<strong>Success!</strong> You successfully confirmed your invitation, you can now <a href="'+url + '">view the project</a>.';
					Flash.create('success', message, 0, {}, true);
				}).catch(function(err){
					console.log("Error")
				})
			} else {
				AccountInvitation.accept({
					id:$scope.invitation.token,
					accountId: $stateParams.accountId
				}, {
				}).$promise.then(function(){
					$scope.completed = true;
					
					$scope.approved = true;
					var url = "/accounts/"+$stateParams.accountId;
					var message = '<strong>Success!</strong> You successfully confirmed your invitation, you can now <a href="'+url + '">view the project</a>.';
					Flash.create('success', message, 0, {}, true);
				}).catch(function(err){
					console.log("Error")
				})
			}
		};
		$scope.reject = function() {
			if ($stateParams.jobId){
				Invitation.reject({
					id:$scope.invitation.token,
					jobId: $stateParams.jobId,
					accountId: $stateParams.accountId
				}, {
				}).$promise.then(function(){
					$scope.completed = true;
					$scope.rejected = true;
					
					var message = '<strong>Ok</strong> You successfully rejected the invitation.';
					Flash.create('info', message, 0, {}, true);
					// window.location.href="/signup"
				}).catch(function(err){
					console.log("Error")
				})
			} else {
				AccountInvitation.reject({
					id:$scope.invitation.token,
					accountId: $stateParams.accountId
				}, {
				}).$promise.then(function(){
					$scope.completed = true;
					$scope.rejected = true;
					
					var message = '<strong>Ok</strong> You successfully rejected the invitation.';
					Flash.create('info', message, 0, {}, true);
					// window.location.href="/signup"
				}).catch(function(err){
					console.log("Error")
				})

			}
		}
	}).catch(function(e){
		$scope.notloggedin = true;
		$scope.url = window.location.href;
		if ($stateParams.jobId){
			Invitation.get({
				id: $stateParams.token,
				jobId: $stateParams.jobId,
				accountId: $stateParams.accountId
			}).$promise.then(function(invitation) {
				console.log("you have "+JSON.stringify(invitation))
				$scope.invitation = invitation;
			}).catch(function(e){
				console.log(e)
			})
		} else {
			AccountInvitation.get({
				id: $stateParams.token,
				accountId: $stateParams.accountId
			}).$promise.then(function(invitation) {
				console.log("you have "+JSON.stringify(invitation))
				$scope.invitation = invitation;
			}).catch(function(e){
				console.log(e)
			})
		}
	})

});
